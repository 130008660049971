body,
button {
  font-family: "Raleway", sans-serif;
}

.open-sans-font {
  font-family: "Open Sans", sans-serif;
}

main {
  overflow: hidden;
}

::selection {
  background: #7ca982;
  color: #fff;
}

/* Scrollbar track */
::-webkit-scrollbar-track {
  background: transparent;
  /* Optional: Change to your desired track color */
  border-radius: 10px;
}

/* Scrollbar handle */
::-webkit-scrollbar {
  width: 7px;
  /* Width of the scrollbar */
  height: 7px;
  /* Also setting height for horizontal scrollbars */
}

::-webkit-scrollbar-thumb {
  background: #555c54;
  /* Color of the scrollbar */
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555c54;
  /* Optional: Change to the color you want on hover */
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #555c54 transparent;
  /* thumb and track color */
}

/* For IE and Edge */
* {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

b {
  font-weight: bold;
}

button {
  border-radius: 8px;
  background: linear-gradient(250deg, #b8d5aa -20.5%, #5e8a64 63.77%);
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  width: 240px;
  height: 54px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  outline: none;
  border: none;
}

button:hover {
  opacity: 0.8;
}

button:active {
  opacity: 0.5;
}

.gap {
  height: 100px;
}

.gap-sm {
  height: 50px;
}

nav,
section {
  max-width: calc(100% - 30px);
  width: 1360px;
  margin: auto;
}

p {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
}

h1 {
  color: #000;
  font-size: 36px;
  font-weight: 400;
  line-height: normal;
  width: 324px;
}

h2 {
  color: #5e8a64;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.1;
}

.green-bg {
  background: #eaf3e6;
}

.dark-green-bg {
  background: #2f3630;
}

.pattern-bg {
  background: url(../../public/images/pattern.svg);
}

.light-green-bg {
  background: #b8d5aa;
}

.garden-bg {
  background: url(../../public//images/japanese-garden-view-through-window.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.hover-effect:hover {
  opacity: 0.8;
}

.hover-effect:active {
  opacity: 0.5;
}
